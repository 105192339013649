import MuiModal from "@mui/material/Modal"
import { Grid, Typography, Box } from "@mui/material"
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import "./base-modal.scss"

export default function BaseModal({ children, handleClose, title, open, ...rest }) {
    return (
        <MuiModal open={open} onClose={handleClose} {...rest}>
            <Grid className="root" container direction="column" wrap="nowrap">
                <Grid container item className="close-modal" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5"><strong>{title}</strong></Typography>
                    <IconButton className="close-icon-button" onClick={handleClose}>
                        <CloseIcon className="icon" />
                    </IconButton>
                </Grid>
                <Grid container item>
                    {children}
                </Grid>
            </Grid>
        </MuiModal>
    )
}