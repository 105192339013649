import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Grid, Button, Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './chat.scss';

const CodeBlock = ({ value, language }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
    };

    return (
        <div style={{ position: 'relative' }}>
            <Button
                onClick={copyToClipboard}
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: '#282c34',
                    color: '#ffffff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                Copy
            </Button>
            <SyntaxHighlighter language={language || 'javascript'} style={okaidia}>
                {value}
            </SyntaxHighlighter>
        </div>
    );
};

const Chat = ({ context, setContext }) => {
    const [isMessageSectionScrolledDown, setIsMessageSectionScrolledDown] = useState(true);
    const messagesSectionRef = useRef(null);

    const ClientMessage = (id, message) => (
        <Grid key={id} style={{ alignSelf: 'flex-end', padding: '10px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#f5f5f5' }}>
            <ReactMarkdown>{message}</ReactMarkdown>
        </Grid>
    );

    const SystemMessage = (id, message) => (
        <Grid key={id} style={{ alignSelf: 'flex-start', maxWidth: '100%' }}>
            <ReactMarkdown
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        return !inline ? (
                            <CodeBlock value={String(children).replace(/\n$/, '')} language={match ? match[1] : ''} {...props} />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        );
                    },
                }}
            >
                {message}
            </ReactMarkdown>
        </Grid>
    );

    const renderTextMessage = (id, user_type, message) =>
        user_type === 'client' ? ClientMessage(id, message) : SystemMessage(id, message);

    const scrollDownMessages = () => {
        messagesSectionRef.current.scrollTo({
            top: messagesSectionRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    const onScroll = ({ target }) => {
        const isAtBottom = (target.scrollHeight - target.scrollTop) <= target.clientHeight + 100;
        setIsMessageSectionScrolledDown(isAtBottom);
    };

    useEffect(() => {
        messagesSectionRef.current.onscroll = onScroll;
    }, []);

    useEffect(() => {
        scrollDownMessages();
    }, [context]);

    return (
        <>
            <Box style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }} />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                className="messages-section"
                gap={4}
                ref={messagesSectionRef}
                style={{
                    height: 'auto',
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    marginBottom: '10px',
                    borderRadius: '5px',
                }}
            >
                {context.map(({ message, user_type, message_type, id }) =>
                    message_type === 'text' ? renderTextMessage(id, user_type, message) : null
                )}
            </Grid>
            {!isMessageSectionScrolledDown ?
                <Button variant="outlined" style={{
                    backgroundColor: "#fff",
                    position: "fixed",
                    bottom: "100px",
                    width: "fit - content",
                    alignSelf: "center",
                    borderRadius: "25px"
                }} onClick={scrollDownMessages}>Ir para o fim</Button>
                : null}
        </>
    );
};

export default Chat;
