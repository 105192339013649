import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import LoginPage from './pages/Login';
import VehiclesPage from './pages/Vehicles';
import ResultsPage from './pages/Results';
import TiersPage from './pages/Tiers';
import QuestionAndAnswers from './pages/QuestionsAndAnswers';

function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<PublicRoute restricted={true} element={<LoginPage />} />} />
          <Route path='/' element={<PrivateRoute element={<ResultsPage />} />} />
          <Route path='/vehicles' element={<PrivateRoute element={<VehiclesPage />} />} />
          <Route path='/tiers' element={<PrivateRoute element={<TiersPage />} />} />
          <Route path='/q&a' element={<PrivateRoute element={<QuestionAndAnswers />} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Router;
