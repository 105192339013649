import { useState } from "react";
import DatePicker from 'react-datepicker'
import { ptBR } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import './date-range-picker.scss'

export default function DateRangePicker({ startDate, endDate, onChange }) {
    return (
        <DatePicker
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            className="date-range-picker"
        />
    );
};