import React from "react";
import { Grid, Button, Divider } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { useAuth } from '../contexts/authContext';
import './main-layout.scss'

function MainLayout({ children }) {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleClick = (ref) => {
        navigate(ref)
    }

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Grid container className="main-container" justifyContent="center">
            <Grid container item xs={12} className="main-container__header" justifyContent="space-evenly" alignItems="center">
                <Grid container item xs={3} justifyContent="center" alignItems="center">
                    <img src="/cisp1.svg" style={{ height: "35px" }} />
                </Grid>
                <Grid container item xs={6} justifyContent="center" alignItems="center">
                    <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/')}>Processar Arquivo</Button>
                    <Divider orientation="vertical" style={{ height: "30px" }} />
                    <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/vehicles')}>Veículos</Button>
                    <Divider orientation="vertical" style={{ height: "30px", marginTop: "15px" }} />
                    <Button style={{ textTransform: 'none' }} onClick={() => handleClick('/tiers')}>Tiers</Button>
                    <Divider orientation="vertical" style={{ height: "30px" }} />
                    <Button style={{ textTransform: 'none', justifyContent: "flex-start" }} onClick={() => handleClick('/q&a')}>Converse com suas notícias</Button>
                    <Divider orientation="vertical" style={{ height: "30px" }} />
                    <Button style={{ textTransform: 'none' }} onClick={() => handleLogout()}>Sair</Button>
                </Grid>
                <Grid container item xs={3} justifyContent="center" alignItems="center">
                    <img src="/sing.png" style={{ height: "65px" }} />
                </Grid>
            </Grid>
            <Divider />
            <Grid container alignItems="center" justifyContent="center" item xs={10} className="main-container__content">
                {children}
            </Grid>
        </Grid >
    )
}

export default MainLayout