import React, { createContext, useState, useContext, useEffect } from 'react';
import { login as loginApi, refreshToken as refreshTokenApi, logout as logoutApi } from '../utils/api';
import jwtEncode from 'jwt-encode';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await refreshTokenApi();
                setUser(response.user_data);
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, [location.pathname]);

    const login = async (data) => {
        try {
            return await loginApi(data);
        } catch (error) {
            throw error;
        }
    };

    const logout = async () => {
        try {
            await logoutApi();
            setUser(null);
            localStorage.removeItem('user');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
