import React from "react";
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PrivateRoute = ({ element, ...rest }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return user ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
