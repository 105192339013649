import { useState, useEffect } from "react"
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Select } from "@mui/material"
import BaseModal from "../../BaseModal"
import { updateVehicle } from "../../../utils/api"

const prepareVehicle = (vehicle = {}) => ({ id: vehicle.id, name: vehicle.name || "", editorship: vehicle.editorship || "", audience: vehicle.audience || "", tier: vehicle.tier || "" })

const EditionModal = ({ close, open, selectedVehicle, refreshData, showAlert }) => {
    const [currentVehicle, setCurrentVehicle] = useState(prepareVehicle())

    const handleSubmit = () => {
        const updated_data = { editorship: currentVehicle.editorship, audience: parseInt(currentVehicle.audience, 10), tier: parseInt(currentVehicle.tier, 10) }
        updateVehicle(currentVehicle.id, updated_data).then(() => {
            refreshData()
            showAlert(`O veículo ${currentVehicle.name} foi atualizado com sucesso!`, "success")
            close()
        }).catch(err => {
            showAlert(`Não foi possível realizar a edição, tente novamente!`, "error")
        })
    }

    const onInputChange = (evt) => {
        const { name, value } = evt.target
        const newVehicle = { ...currentVehicle, [name]: value }

        setCurrentVehicle(newVehicle)
    }

    useEffect(() => {
        setCurrentVehicle(prepareVehicle(selectedVehicle))
    }, [selectedVehicle]);

    return (
        <BaseModal open={open} handleClose={close} title="Modal de Edição do veículo">
            <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                <TextField
                    name="name"
                    label="Media Outlet"
                    variant="standard"
                    value={currentVehicle["name"]}
                    disabled
                    style={{ width: '100%' }}
                />
                <TextField
                    name="editorship"
                    label="Editorship"
                    variant="standard"
                    value={currentVehicle["editorship"]}
                    style={{ width: '100%' }}
                    onChange={onInputChange}
                />
                <TextField
                    name="audience"
                    label="Audience"
                    variant="standard"
                    value={currentVehicle["audience"]}
                    style={{ width: '100%' }}
                    onChange={onInputChange}
                />
                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel id="tier-label">Tier</InputLabel>
                        <Select
                            labelId="tier-label"
                            name="tier"
                            value={currentVehicle["tier"]}
                            onChange={onInputChange}
                        >
                            <MenuItem value="1">Tier 1</MenuItem>
                            <MenuItem value="2">Tier 2</MenuItem>
                            <MenuItem value="3">Tier 3</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Box mt={1} />
                <Button variant="contained" onClick={handleSubmit}>Editar</Button>
            </Grid>
        </BaseModal>
    )
}

export default EditionModal