import { useEffect, useState } from 'react'
import MainLayout from "../../layouts"
import { Box, Button, Drawer, Grid, Paper, Skeleton, Typography } from '@mui/material'
import Select from "../../components/Select"
import DateRangePicker from "../../components/DateRangePicker"
import { getCustomers, getVehicles } from "../../utils/api"
import Chat from '../../components/QuestionsAndAnswers/Chat'
import QuestionBar from '../../components/QuestionsAndAnswers/QuestionBar'
import AutoCompleteSelect from '../../components/AutoComplete'

const QuestionAndAnswersSkeleton = () => {
    return (
        <Grid container justifyContent="center" style={{ padding: "32px 0" }}>
            <Typography variant="h6"><Skeleton width={100} /></Typography>
            <Skeleton width={300} height={100} />
            <Skeleton width={300} height={100} />
            <Skeleton width={300} height={100} />
        </Grid>
    )
}

const QuestionAndAnswers = () => {

    const sevenDaysAgo = () => {
        const date = new Date();
        date.setDate(date.getDate() - 7);
        return date;
      };
      
    const today = () => new Date();

    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState()
    const [vehicles, setVehicles] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState(sevenDaysAgo());
    const [endDate, setEndDate] = useState(today());
    const [context, setContext] = useState([{
        "user_type": "system",
        "message_type": "text",
        "message": "Como posso ajudar?",
        "id": 5
    }])

    const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSimpleChange = (value) => {
        setSelectedClient(value)
    }

    const handleVehicleChange = (value) => {
        setSelectedVehicle(value)
    }

    useEffect(() => {
        setIsLoading(true)
        getCustomers().then(({ data }) => {
            setClients(data.map(({ id, title }) => ({ label: title, value: id })))
        }).catch(err => {
            console.log(err)
        })
        getVehicles().then(({ data }) => {
            setIsLoading(false)
            setVehicles(data.map(({ id, name }) => ({ label: name, value: id })))
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }, [])

    return (
        <MainLayout>
            <Drawer
                variant="permanent"
                sx={{
                    width: '300px',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '300px', boxSizing: 'border-box', height: 'calc(100vh - 75px)', top: '75px', backgroundColor: '#f5f5f5' },
                    ['& .MuiGrid-container']: { justifyContent: "center" }
                }}
            >
                <Grid container sx={{ width: "100%", padding: '16px', overflow: 'hidden' }} direction="column">
                    <Typography variant="h6" style={{ textAlign: "center" }}>Filtros</Typography>
                    <Box py={1} />
                    <AutoCompleteSelect label={"Cliente"} selectedValue={selectedClient} onChange={handleSimpleChange} options={clients} />
                    <Box my={1} />
                    <DateRangePicker startDate={startDate} endDate={endDate} onChange={onDateChange} />
                    <Box py={1} />
                    <AutoCompleteSelect label={"Veículo"} selectedValue={selectedVehicle} onChange={handleVehicleChange} options={vehicles} />
                </Grid>
            </Drawer>
            <Grid container item direction="column" wrap="nowrap" xs={8} style={{ height: "100%", padding: "100px 0 30px 0" }}>
                <Chat context={context} setContext={setContext} />
                <QuestionBar
                    context={context}
                    startDate={startDate}
                    endDate={endDate}
                    selectedVehicle={selectedVehicle}
                    selectedClient={selectedClient}
                    setContext={setContext}
                />
            </Grid>
        </MainLayout >
    )
}

export default QuestionAndAnswers