import { useState, useEffect } from "react"
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, TextField, Select } from "@mui/material"
import BaseModal from "../../BaseModal"
import { updateTier } from "../../../utils/api"

const prepareTier = (tier = {}) => ({ id: tier.id, value: tier.value || 3, vehicle: tier.vehicle || { name: "" }, customer: tier.customer || { title: "" } })


const EditionModal = ({ close, open, selectedTier, refreshData, showAlert }) => {
    const [currentTier, setCurrentTier] = useState(prepareTier(selectedTier))

    const handleSubmit = () => {
        const updated_data = { id: currentTier.id, value: parseInt(currentTier.value, 10) }
        updateTier(currentTier.id, updated_data).then(() => {
            refreshData()
            showAlert(`O tier do veículo ${currentTier.vehicle.name} para o cliente ${currentTier.customer.title} foi atualizado com sucesso!`, "success")
            close()
        }).catch(err => {
            showAlert(`Não foi possível realizar a edição, tente novamente!`, "error")
        })
    }

    const onInputChange = (evt) => {
        const { name, value } = evt.target
        const newTier = { ...currentTier, [name]: value }

        setCurrentTier(newTier)
    }

    useEffect(() => {
        setCurrentTier(prepareTier(selectedTier))
    }, [selectedTier]);

    return (
        <BaseModal open={open} handleClose={close} title="Modal de Edição de Tier">
            <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                <TextField
                    name="customer"
                    label="Cliente"
                    variant="standard"
                    value={currentTier["customer"]["title"]}
                    disabled
                    style={{ width: '100%' }}
                />
                <TextField
                    name="vehicle"
                    label="Vehicle"
                    variant="standard"
                    value={currentTier["vehicle"]["name"]}
                    disabled
                    style={{ width: '100%' }}
                />
                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel id="tier-label">Tier</InputLabel>
                        <Select
                            labelId="tier-label"
                            name="value"
                            value={currentTier["value"]}
                            onChange={onInputChange}
                        >
                            <MenuItem value="1">Tier 1</MenuItem>
                            <MenuItem value="2">Tier 2</MenuItem>
                            <MenuItem value="3">Tier 3</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Box mt={1} />
                <Button variant="contained" onClick={handleSubmit}>Editar</Button>
            </Grid>
        </BaseModal>
    )
}

export default EditionModal