import axiosInstance from "./apiConfig";

export const login = async (data) => {
    try {
        const response = await axiosInstance.post('/login', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const refreshToken = async () => {
    try {
        const response = await axiosInstance.post('/refresh-token');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axiosInstance.post('/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const checkStatus = async () => {
    return await axiosInstance.get(`/`)
}

export const generateReport = async (startDate, endDate, customerId, language) => {
    return await axiosInstance.post(`/report`, { start_date: startDate, end_date: endDate, customer_id: customerId, language }, {
        responseType: 'blob'
    })
}

export const getVehicles = async () => {
    return await axiosInstance.get(`/vehicles`)
}

export const updateVehicle = async (id, updatedData) => {
    return await axiosInstance.put(`/vehicles/${id}`, { ...updatedData })
}

export const getCustomers = async () => {
    return await axiosInstance.get('/customers')
}

export const getTiers = async () => {
    return await axiosInstance.get('/tiers')
}

export const getTiersByCustomer = async (customerId) => {
    return await axiosInstance.get(`/tiers/${customerId}`)
}

export const updateTier = async (id, updatedData) => {
    return await axiosInstance.put(`/tiers/${id}`, { ...updatedData })
}