import { Autocomplete, FormControl, TextField } from "@mui/material";

export default function AutoCompleteSelect({ label, selectedValue, onChange, options }) {
    return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 200, margin: 0 }}>
            <Autocomplete
                value={options.find(option => option.value === selectedValue) || null}
                onChange={(event, newValue) => {
                    onChange(newValue ? newValue.value : "");
                }}
                options={options}
                getOptionLabel={(option) => option.label || ""}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => (
                    <TextField {...params} label={label} sx={{ backgroundColor: 'white' }} />
                )}
            />
        </FormControl>
    );
}
