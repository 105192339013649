export const parseFilename = (filename) => {
    const regex = /filename="([^"]+)"/;

    const match = filename.match(regex);

    if (match && match[1]) {
        const fileName = decodeURIComponent(match[1]);
        return fileName
    } else {
        return 'arquivo_processado'
    }
}