import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './login-page.scss';
import { useAuth } from '../../contexts/authContext';
import { Button, CssBaseline, TextField, Box, Typography, Container, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import jwtEncode from 'jwt-encode';
import { useSnackbar } from 'notistack'

const theme = createTheme();

function LoginPage() {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: false, password: false });
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar()

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const emailError = !validateEmail(email);
        const passwordError = password === '';
        setLoading(true);

        if (!emailError && !passwordError) {
            try {
                const data = { email, password };
                const response = await login(data);
                const userObj = jwtEncode(response.user_data, process.env.REACT_APP_SECRET_KEY);

                localStorage.setItem('user', userObj);

                enqueueSnackbar({ message: 'Login foi realizado com sucesso. Você será redirecionado para o sistema.', variant: "success" })

                setTimeout(() => {
                    navigate('/')
                }, 1500);
            } catch (error) {
                let message = error.response?.status === 401
                    ? 'Falha no login. Verifique suas credenciais e tente novamente.'
                    : 'Algo deu errado. Por favor, tente novamente mais tarde.'

                enqueueSnackbar({ message, variant: "error" })

                setLoading(false);
            }
        } else {
            setErrors({ email: emailError, password: passwordError });
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="login-container">
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 3,
                        }}
                    >
                        <img src="/cisp1.svg" alt="CISP1" style={{ width: '100px', height: '50px', marginLeft: '10px' }} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '2rem',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={errors.email}
                                helperText={errors.email ? 'E-mail inválido' : ''}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={errors.password}
                                helperText={errors.password ? 'Senha é obrigatória' : ''}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                                endIcon={loading && <CircularProgress size={20} />}
                                className=""
                            >
                                {loading ? 'Entrando...' : 'Entrar'}
                            </Button>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Esqueceu a senha?
                                    </Link>
                                </Grid>
                            </Grid> */}
                        </Box>

                    </Box>
                </Container>
            </div>

        </ThemeProvider>
    );
}

export default LoginPage;
