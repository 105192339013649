import axios from 'axios'
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const csrfAccessToken = Cookies.get('csrf_access_token');
        const csrfRefreshToken = Cookies.get('csrf_refresh_token');

        if (config.url.includes('/refresh-token')) {
            config.headers['X-CSRF-TOKEN'] = csrfRefreshToken;
        } else {
            config.headers['X-CSRF-TOKEN'] = csrfAccessToken;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;